import { FC, createElement, useCallback, useEffect, useMemo, useState } from 'react';
import ActionBaseProps from '../ActionBaseProps';
import AddFieldBar from './AddFieldBar';
import { FormActionContext, useFormAction } from '../../../../contexts/FormActionContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { FormRendererInfoContext, useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { useFormSectionRendererInfo } from '../../../../contexts/FormSectionRendererContext';
import ActionTypes from '../../ActionTypes';
import AdHocActionWrapper from './AdHocActionWrapper';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { AdHocAnswerResponse } from '../../../../models/Form';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';

type Data = { buttonText: string };

const AdHocFieldsAction: FC<ActionBaseProps<Record<string, never>, Data>> = (props) => {
  const { data } = props;
  const { readOnly, adHocAnswers, onAdHocAnswer, sortAdHocAnswers } = useFormRendererInfo();
  const { step: currentStep } = useFormSectionRendererInfo();
  const { busySavingAction, createPlaceholder, deletePlaceholder, currentAction } = useFormAction(props);
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation('activity-type');

  const responses = useMemo(() => {
    return adHocAnswers[currentAction.id] ?? [];
  }, [adHocAnswers, currentAction.id]);

  const addField = useCallback(() => {
    // Change this when we have more than one type of ad-hoc field
    onAdHocAnswer(currentAction.id, uuid(), { questionData: { type: 'TextInputAction', title: '' }, data: '', sortOrder: responses.length });
    setLoading(true);
  }, [currentAction.id, onAdHocAnswer, responses.length]);

  useEffect(() => {
    setLoading(false);
  }, [responses.length]);

  const addAnswer = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldId: string, answer: any) => {
      return onAdHocAnswer(currentAction.id, fieldId, { data: answer });
    },
    [currentAction.id, onAdHocAnswer],
  );

  const fieldDataTransformer = useCallback(
    (field: AdHocAnswerResponse) => {
      let newData = LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(currentAction), currentLanguage);
      // field.question is question data, not answer data
      newData = { ...newData, ...field.question };
      // Fix title/question mismatch
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newData.question = (newData as any).title ?? newData.question ?? '';

      return newData;
    },
    [currentAction, currentLanguage],
  );

  const onAnswerCallbacks = useMemo(() => {
    return responses.map((field) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (res: any) => addAnswer(field.fieldId, res);
    });
  }, [addAnswer, responses]);

  const getIndex = useCallback((id: UniqueIdentifier) => responses.findIndex((x) => x.fieldId === id), [responses]);

  const onDragStart = useCallback((event: DragStartEvent) => {
    if (!event.active) {
      return;
    }

    setActiveDragId(event.active.id);
  }, []);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveDragId(null);

      if (event.over) {
        const activeIndex = activeDragId ? getIndex(activeDragId) : -1;
        const overIndex = getIndex(event.over.id);

        if (activeIndex !== overIndex) {
          const result = arrayMove(responses, activeIndex, overIndex);
          sortAdHocAnswers(
            currentAction.id,
            result.map((x) => x.fieldId),
          );
        }
      }
    },
    [activeDragId, currentAction.id, getIndex, responses, sortAdHocAnswers],
  );

  const sortableItems = useMemo(() => responses.map((x) => x.fieldId), [responses]);

  if (responses.length === 0) {
    return <AddFieldBar loading={loading} buttonTitle={data.buttonText || t('ad-hoc-fields.add-field-fallback')} onClick={addField} />;
  }

  return (
    <div className="border-gray-3 relative my-4 rounded-[5px] border py-4 pl-1 pr-2">
      <span className="text-dpm-14 absolute -top-4 left-4 bg-white px-2 py-1">{data.question ?? t('ad-hoc-fields.group-title-fallback')}</span>

      <FormRendererInfoContext.Consumer>
        {(frInfo) => (
          <FormRendererInfoContext.Provider value={{ ...frInfo, featureToggles: { ...(frInfo?.featureToggles ?? {}), enableInlineTitleEdit: true } }}>
            <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}>
              <SortableContext items={sortableItems} disabled={busySavingAction}>
                {responses.map((field, i) => (
                  <FormActionContext.Provider
                    key={field.fieldId}
                    value={{
                      currentSection: currentStep,
                      currentAction: {
                        ...field,
                        id: currentAction.id,
                        type: field.question.type,
                        visible: 'true',
                        data: fieldDataTransformer(field),
                        number: field.number,
                      },
                      actionValid: true,
                      riskLevel: 0, // TODO
                      readOnly: readOnly || busySavingAction,
                      busySavingAction: busySavingAction,
                      onAnswer: onAnswerCallbacks[i],
                      onValid: () => ({}), // Do nothing
                      createPlaceholder,
                      deletePlaceholder,
                    }}
                  >
                    <AdHocActionWrapper isLast={i === responses.length - 1}>
                      {createElement(ActionTypes[field.question.type].editAction, {
                        id: currentAction.id,
                        data: fieldDataTransformer(field),
                        response: field.data,
                        required: false,
                      })}
                    </AdHocActionWrapper>
                  </FormActionContext.Provider>
                ))}
              </SortableContext>
            </DndContext>
          </FormRendererInfoContext.Provider>
        )}
      </FormRendererInfoContext.Consumer>

      <div className="mt-4">
        <AddFieldBar loading={loading} buttonTitle={data.buttonText || t('ad-hoc-fields.add-field-fallback')} onClick={addField} />
      </div>
    </div>
  );
};

export default AdHocFieldsAction;
