import { FC } from 'react';
import { mouseAndKeyboardCallbackProps } from '../../../../utils/ComponentUtils';
import PlusIcon from '../../../shared/icon/PlusIcon';
import { useTranslation } from 'react-i18next';
import Loader from '../../../shared/Loader';

type Props = {
  loading: boolean;
  buttonTitle?: string;
  onClick: () => void;
};

const AddFieldBar: FC<Props> = (props) => {
  const { loading, buttonTitle, onClick } = props;

  const { t } = useTranslation('activity-type');

  return (
    <div
      {...mouseAndKeyboardCallbackProps(onClick)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={`group/ad-hoc-add flex cursor-pointer items-center gap-4 rounded p-1 ${loading ? 'pointer-events-none' : ''}`}
    >
      <div className="border-gray-3 group-hover/ad-hoc-add:border-accent-1 flex-grow border-b transition-colors" />

      {loading ? (
        <div className="bg-gray-3 h-8 w-8 rounded-[4px] p-1 transition-colors">
          <Loader centered={false} size={6} />
        </div>
      ) : (
        <PlusIcon className="bg-gray-3 text-gray-2 group-hover/ad-hoc-add:bg-accent-1 h-8 w-8 rounded-[4px] p-2 transition-colors group-hover/ad-hoc-add:text-white" />
      )}

      <span className="text-gray-2">{buttonTitle ?? t('ad-hoc-fields.add-field-fallback')}</span>
      <div className="border-gray-3 group-hover/ad-hoc-add:border-accent-1 flex-grow border-b transition-colors" />
    </div>
  );
};

export default AddFieldBar;
